<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
      sm="12"
      class="pt-0"
    >
      <lgpd-grid />
    </v-col>
  </v-row>
</template>

<script>
import LgpdGrid from '@/components/LgpdGrid'

export default {
  name: 'Lgpd',
  components: {
    'lgpd-grid': LgpdGrid
  }
}
</script>
